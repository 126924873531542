<template>
  <div class="premium-page">
    <div class="container d-flex flex-wrap align-items-start justify-content-center">
      <main
          class="row page-content d-flex flex-column justify-content-center">
        <div class="membership-title-wrapper d-flex justify-content-around align-items-start w-100">
          <div class="col-sm-12 col-lg-6 membership-left d-flex flex-column">
            <div class="membership-title" v-html="$t('onboarding.record_your_calls')"></div>
            <span class="membership-subtitle"> {{ $t('onboarding.easiest_way') }}</span>
            <div class="btn-wrapper">
              <button class="membership-btn mt-5" @click="getStarted" :class="{'membership-btn-disabled': !checked}"
                      :disabled="!checked">
                {{ $t('onboarding.continue') }}
              </button>
            </div>
            <div class="accept-politics-container">
              <span v-if="!checked" class="accept-politics">Please accept our Terms and Policies</span>
            </div>
            <label class="accepting-conditions_container">
              <div>
                <input class="accepting-conditions-input" type="checkbox"/>
                <span class="checkmark" :class="{'checkmark-checked': checked}" @click="checked=!checked"></span>
              </div>

              <span>By continuing I agree with <a href="https://simple-recorder.com/onboarding/terms/">Terms and Conditions</a>, <a
                  href="https://simple-recorder.com/onboarding/policy/">Privacy Policy</a>, <a
                  href="https://simple-recorder.com/onboarding/refund/">Money-Back Policy</a>.</span>
            </label>


          </div>
          <div class="col-sm-12 col-lg-6 membership-img">
            <!--            <img-->
            <!--                srcset="@/assets/img/iPhone-12.png 320w, @/assets/img/iPhone-12@2x.png 480w, @/assets/img/iPhone-12@3x.png 800w"-->
            <!--                sizes="(max-width: 320px) 280px,-->
            <!--                          (max-width: 480px) 440px,-->
            <!--                          282px"-->
            <!--                src="@/assets/img/iPhone-12@3x.png" alt="iPhone-12"/>-->
            <img src="@/assets/img/iPhone-onboarding.png">
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import config from '@/config';
import {mapGetters} from 'vuex';

export default {
  name: 'Onboarding',
  data: () => ({
    checked: true,
    utmTagsRequired: ['af_pid', 'af_c', 'af_channel', 'af_c_id', 'af_adset', 'af_adset_id', 'af_ad', 'af_ad_id', 'utm_campaign', 'utm_adset'],
  }),
  async beforeMount() {
    this.initFBPixel(this.fbPixelId);
    this.initClarity(config.clarityId.onboardingId)
    Vue.use(VueGtag, {config: {id: config.gaId.onboarding.prod}});
    this.setAuthHashCookie();
    await this.$store.dispatch('appStore/getTargetingValue');
  },
  mounted() {
    this.amplitudeEvent('Welcome shown');
    this.gtagEvent('Page_View', {event_category: 'View', event_label: 'Page_View_Onboarding'});
    this.pixelEvent('PageView');
  },
  computed: {
    ...mapGetters({
      targetingValue: 'appStore/targetingValue'
    }),
    fbPixelId() {
      return window.location.host === config.siteHost ? config.fbPixel.onboarding.prod : config.fbPixel.onboarding.dev;
    },
    offerHash() {
      return window.location.host === config.siteHost ? config.offerHash.onboarding : null;
    },
    isMobileDevice() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    isRedirectScenario() {
      // return true;
      return !this.isMobileDevice || this.targetingValue !== 'ready' || !this.isAllUtmTagsPresent;
    },
    isAllUtmTagsPresent() {
      return this.utmTagsRequired.every(tag => Object.keys(this.$route.query).includes(tag));
    }
  },
  methods: {
    getStarted() {
      this.gtagEvent('Button_click', {event_category: 'Click', event_label: 'Button_click_Get_Started'});
      this.amplitudeEvent('Get started clicked');

      // if (0 && this.isRedirectScenario) {
      //   this.redirectToAppStore();
      // } else {
      //   this.$router.push({name: 'quiz'});
      // }
      this.$router.push({name: 'quiz'});
    },
    redirectToAppStore() {
      location.href = config.appStoreUrl;
    },
    setAuthHashCookie() {
      this.$cookies.set('auth_hash', this.offerHash, '1m', '/', config.siteHost, true, 'Lax');
    }
  }
};
</script>

<style lang="scss" scoped>
.premium-page::v-deep {
  padding: 20px 0 320px;

  .container {
    max-width: none;
    align-items: flex-start;

    .page-content {
      min-height: calc(100vh - 230px);
      flex: 1;
      padding: 0 30px 55px;

      .btn-wrapper {
        text-align: left;
        margin-bottom: 40px;

        .membership-btn {
          width: 250px;
          padding: 15px 25px;
          background: linear-gradient(
                  249.09deg, #5BCF74 7.89%, #54A96C 73.86%);
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          border: 0;
          border-radius: 30px;
          cursor: pointer;
        }
      }

      .membership-title-wrapper {
        margin-top: 50px;

        > div {
          padding: 0 30px;
        }

        .membership-left {
          .membership-title {
            text-align: left;
            color: #000;
            font-size: 64px;
            font-weight: 600;
            line-height: normal;
          }

          .membership-subtitle {
            text-align: left;
            font-size: 32px;
            line-height: 40px;
            color: #000;
            margin-top: 16px;
            margin-bottom: 44px;
          }
        }

        .membership-img {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .membership-img img {
          height: auto;
          width: 100%;
          max-width: 684px !important;
        }
      }
    }

    .membership-btn-disabled {
      pointer-events: none;
      opacity: .6;
    }

    .acceptingConditions {
      position: relative;
      left: 7%;
    }

    .accepting-conditions_container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      position: relative;
      margin-top: 40px;
    }

    .accepting-conditions_container > div {
      width: 20px;
    }

    .accepting-conditions_container > span {
      font-size: 16px;
      line-height: 22px;
      color: #100E0E;
      width: 90%;
      padding-left: 10px;
      text-align: left;
    }

    .accepting-conditions_container > span a {
      color: #100E0E;
      text-decoration: underline;
    }

    .accepting-conditions_container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .accepting-conditions_container .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      margin-top: 2px;
      cursor: pointer;
    }

    .accepting-conditions_container input ~ .checkmark {
      background-color: transparent;
      border: 1px solid rgba(31, 27, 26, 0.8);
      border-radius: 3px;
      width: 18px;
      height: 18px;
    }

    //.accepting-conditions_container input:checked ~ .checkmark {
    //  background-color: transparent;
    //}

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    //.accepting-conditions_container input:checked ~ .checkmark:after {
    //  display: block;
    //}

    .checkmark-checked:after {
      display: block;
    }

    .accepting-conditions_container .checkmark:after {
      left: 5px;
      top: 0;
      width: 6px;
      height: 12px;
      border: solid black;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .accept-politics-container {
      height: 22px;
    }

    .accept-politics {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      color: red;
      font-size: 13px;
      line-height: 22px;
    }

  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 1279px) {
  .premium-page {
    padding: 0;

    .page-content {
      max-width: 100%;
      padding: 0 0 20px;
      border: none;
    }
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 1199px) {

  .premium-page {
    .container {
      .accepting-conditions_container {
        width: 50%;
      }
    }
  }

  .membership-title-wrapper {
    flex-direction: column;
    align-items: center !important;

    .membership-left {
      order: 1;
      width: 100%;
      align-items: center;

      .membership-title, .membership-subtitle {
        text-align: center !important;
      }

      .btn-wrapper {
        display: flex;
        justify-content: center;
      }
    }

    .membership-img {
      width: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {


  .premium-page .container .page-content .membership-title-wrapper {
    margin-top: 0;
    padding-top: 20px;
  }

  .premium-page .container .page-content .btn-wrapper .membership-btn {
    min-width: 250px;
    width: 100%;
    margin-top: 0 !important;
  }

  .premium-page .container .accepting-conditions_container {
    width: 100%;
  }

  .premium-page .container .page-content .membership-title-wrapper > div {
    padding: 0;
  }

  .premium-page .container .page-content {
    padding: 0 10px 55px;
  }


  .page-content {
    .package-price {
      .additional-text {
        display: block;
      }
    }
  }

  .membership-title-wrapper {
    flex-direction: column-reverse;
    margin-top: 30px;

    .membership-left {
      margin-top: 50px;
      order: -1;
    }

    .membership-title {
      font-weight: 500;
      font-size: 34px !important;
    }

    .membership-subtitle {
      font-size: 18px !important;
      line-height: 26px !important;
    }

    .btn-wrapper {
      width: 100%;
      padding: 25px 0;
      text-align: center !important;
      z-index: 10;
    }

    .membership-img {
      //margin-left: -95px;

      img {
        height: 600px;
        //width: auto !importanzt;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 375px) {
  .membership-title-wrapper {
    margin-top: 0;

    .membership-img {
      //margin-left: -60px;

      img {
        height: 400px;
      }
    }
  }
}
</style>
